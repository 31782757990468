import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { loginUrl } from '@/js/routing'
import router from '../router'

Vue.use(VueAxios, axios)

axios.interceptors.request.use(config => {
  let token = sessionStorage.getItem('token')

  if (config.url !== loginUrl() && token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, error => {
  return Promise.reject(error)
})

axios.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401 && error.response.config.url !== loginUrl()) {
    sessionStorage.removeItem('token')

    router.push({ name: 'home' })
  }

  return Promise.reject(error)
})
