import Vue from 'vue'
import { BVConfigPlugin, BAlert, BButton, BCard, BCardText, BCol, BCollapse, BForm, BFormCheckbox, BFormCheckboxGroup,
  BFormDatepicker, BFormFile, BFormGroup, BFormInput, BFormRow, BFormSelect, BInputGroup, BInputGroupAppend, BLink, BModal, BNavbar, BNavItem,
  BNavbarBrand, BNavbarNav, BNavbarToggle, BPagination, BRow, BTable, BTableSimple, BTbody, BTd, BTr, VBTooltip, ToastPlugin } from 'bootstrap-vue'

// Vue.use(BootstrapVue);
Vue.use(BVConfigPlugin)
Vue.component('b-alert', BAlert)
Vue.component('b-button', BButton)
Vue.component('b-card', BCard)
Vue.component('b-card-text', BCardText)
Vue.component('b-col', BCol)
Vue.component('b-collapse', BCollapse)
Vue.component('b-form', BForm)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-form-datepicker', BFormDatepicker)
Vue.component('b-form-file', BFormFile)
Vue.component('b-form-group', BFormGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-form-row', BFormRow)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-input-group-append', BInputGroupAppend)
Vue.component('b-modal', BModal)
Vue.component('b-link', BLink)
Vue.component('b-navbar', BNavbar)
Vue.component('b-nav-item', BNavItem)
Vue.component('b-navbar-brand', BNavbarBrand)
Vue.component('b-navbar-nav', BNavbarNav)
Vue.component('b-navbar-toggle', BNavbarToggle)
Vue.component('b-pagination', BPagination)
Vue.component('b-row', BRow)
Vue.component('b-table', BTable)
Vue.component('b-table-simple', BTableSimple)
Vue.component('b-tbody', BTbody)
Vue.component('b-td', BTd)
Vue.component('b-tr', BTr)
Vue.directive('b-tooltip', VBTooltip)
Vue.use(ToastPlugin)
