const API_URL = process.env.URL
const login = '/login'
const clientCredentials = '/client-credentials'
const event = '/event'
const location = '/location'
const description = '/description'
const eventType = '/event-type'
const income = '/income'
const incomeEvent = '/income-event'
const account = '/account'
const balance = '/balance'
const invoice = '/invoice'
const auth = '/auth'

const list = '/list'
const dates = '/dates'
const charts = '/charts'
const subtract = '/subtract'
const validate = '/validate'
const superset = '/superset'

module.exports = {
  loginUrl: () => `${API_URL}${auth}${login}`,
  clientCredentialsUrl: () => `${API_URL}${clientCredentials}`,
  eventUrl: () => `${API_URL}${event}`,
  eventListUrl: () => `${API_URL}${event}${list}`,
  eventDatesUrl: () => `${API_URL}${event}${dates}`,
  eventChartsUrl: () => `${API_URL}${event}${charts}`,
  locationUrl: () => `${API_URL}${location}`,
  descriptionUrl: () => `${API_URL}${description}`,
  eventTypeUrl: () => `${API_URL}${eventType}`,
  incomeUrl: () => `${API_URL}${income}`,
  incomeListUrl: () => `${API_URL}${income}${list}`,
  incomeEventUrl: () => `${API_URL}${incomeEvent}`,
  incomeEventListUrl: () => `${API_URL}${incomeEvent}${list}`,
  incomeEventChartsUrl: () => `${API_URL}${incomeEvent}${charts}`,
  accountUrl: () => `${API_URL}${account}`,
  accountListUrl: () => `${API_URL}${account}${list}`,
  accountSubtractUrl: () => `${API_URL}${account}${subtract}`,
  balanceUrl: () => `${API_URL}${account}${balance}`,
  invoiceUrl: () => `${API_URL}${invoice}`,
  invoiceListUrl: () => `${API_URL}${invoice}${list}`,
  invoiceValidateUrl: () => `${API_URL}${invoice}${validate}`,
  supersetTokenUrl: () => `${API_URL}${auth}${superset}`
}
