import Vue from 'vue'

import './plugins/bootstrap-vue'
import './plugins/font-awesome'
import './plugins/vue-axios'
import './plugins/vue-debounce'
import './plugins/vue-day'
import './plugins/vue-screen'
import './plugins/vue-sweetalert2'

import App from './App.vue'
import router from './router'

import './scss/global.scss'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
