import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// export default new Router({
//     mode: 'history',
//     routes: [
//         {
//             path: '/',
//             name: 'home',
//             component: require('../views/Home').default
//         },
//         {
//             path: '',
//             component: require('../views/Main').default,
//             children: [
//                 {
//                     path: '/event',
//                     name: 'event',
//                     component: require('../views/Event').default
//                 },
//                 {
//                     path: '/summary',
//                     name: 'summary',
//                     component: require('../views/Dashboard').default
//                 },
//                 {
//                     path: '/income',
//                     name: 'income',
//                     component: require('../views/Income').default
//                 },
//                 {
//                     path: '/account',
//                     name: 'account',
//                     component: require('../views/Account').default
//                 },
//                 {
//                     path: '/invoice',
//                     name: 'invoice',
//                     component: require('../views/Invoice').default
//                 }
//             ]
//         },
//         { path: '*', component: require('../views/Home').default }
//     ],
//     scrollBehavior() {
//         return { x: 0, y: 0 }
//     }
// });

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/Home')
    },
    {
      path: '',
      component: () => import('../views/Base'),
      children: [
        {
          path: '/event',
          name: 'event',
          component: () => import('../views/Event')
        },
        {
          path: '/summary',
          name: 'summary',
          component: () => import('../views/Dashboard')
        },
        {
          path: '/income',
          name: 'income',
          component: () => import('../views/Income')
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('../views/Account')
        },
        {
          path: '/invoice',
          name: 'invoice',
          component: () => import('../views/Invoice')
        }
      ]
    },
    { path: '*', component: () => import('../views/Home') }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})
