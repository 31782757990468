<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      env: process.env.NODE_ENV
    }
  }
}
</script>

<style>
</style>
