import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'

const options = {
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  reverseButtons: true,
  showLoaderOnConfirm: true,
  confirmButtonColor: '#28a745',
  cancelButtonColor: '#dc3545'
}

Vue.use(VueSweetalert2, options)
